<script setup>
import { useInfoFacturasCapitaStore } from '@/apps/pharmasan/facturacion/capita/stores/infoFacturasCapita.store'
import { storeToRefs } from 'pinia'
import { useStore } from 'vuex'
import { onMounted, onUnmounted, ref } from 'vue'

const store = useStore()
const user = store.getters['auth/getPeople']
const infoFacturasCapitaStore = useInfoFacturasCapitaStore()
const {
  _toggleUdfFactura,
  _infoFacturaCapita,
  _listModalidadContrato,
  _listMotivoAutorizacion,
  _listContratoAutorizacion,
  _listYears,
  _listMeses,
  _listZonas,
  _listRegimenF,
  _listEstadosCartera,
  _listRips,
  _formSubmitted
} = storeToRefs(infoFacturasCapitaStore)
const windowWidth = ref(window.innerWidth)

// Handle resize
const handleResize = () => {
  windowWidth.value = window.innerWidth
}

onMounted(() => {
  window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})
</script>

<template>
  <div class="box h-full" :class="{'hidden': !_toggleUdfFactura}">
    <div class="mt-0 flex flex-col" style="height: 892px;padding-bottom: 16px;">
      <!-- Titulo del UDF -->
      <div class="flex p-4 items-center border-b border-gray-200 dark:border-dark-5">
        <h3 class="mr-auto text-base font-medium">UDF</h3>
      </div>

      <!-- Contenido -->
      <div class="grid md:grid-cols-12 sm:grid-cols-12 grid-cols-1 gap-4 p-4 overflow-y-auto">
        <!-- Modalidad de contrato -->
        <div class="col-span-12 sm:col-span-12">
          <div class="flex flex-col xxl:flex-row xxl:justify-between xxl:items-center">
            <label for="U_PHR_ModContrato" class="block w-full xxl:w-4/7 text-left xxl:text-right text-xs font-medium text-gray-700 mb-1 xxl:mr-2">
              Modalidad Contrato:<span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
            </label>
            <Dropdown
              id="U_PHR_ModContrato"
              v-model="_infoFacturaCapita.U_PHR_ModContrato"
              :options="_listModalidadContrato"
              optionLabel="label"
              optionValue="value"
              placeholder="Seleccione una opción"
              :filter="true"
              scrollHeight="300px"
              filterPlaceholder="Buscar por nombre"
              autoFilterFocus
              class="w-full"
              :style="{
                'max-width': windowWidth < 1600 ? '100%' : '60%',
                'min-width': windowWidth < 1600 ? '100%' : '60%',
              }"
              disabled
              :pt="{
                root: ({
                  props, state, context
                }) => ({
                  style: 'height: 2rem;' + (_formSubmitted && !_infoFacturaCapita.U_PHR_ModContrato ? 'border-color: rgb(220, 38, 38)' : '')
                }),
                input: { style: 'font-size: 0.875rem; padding: 0.25rem 0.5rem' },
                panel: { style: 'font-size: 0.75rem; max-width: 100px;' },
                item: { style: 'padding: 0.3rem 0.8rem; line-height: 1.4; word-wrap: break-word; white-space: normal; border-bottom: 1px dashed #eee' }
              }"
            />
          </div>
        </div>

        <!-- Motivo de autorización -->
        <div class="col-span-12 sm:col-span-12">
          <div class="flex flex-col xxl:flex-row xxl:justify-between xxl:items-center">
            <label for="U_PHR_MotAutoriza" class="block w-full xxl:w-4/7 text-left xxl:text-right text-xs font-medium text-gray-700 mb-1 xxl:mr-2">
              Motivo Autorización:<span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
            </label>
            <Dropdown
              id="U_PHR_MotAutoriza"
              v-model="_infoFacturaCapita.U_PHR_MotAutoriza"
              :options="_listMotivoAutorizacion"
              optionLabel="label"
              optionValue="value"
              placeholder="Seleccione una opción"
              :filter="true"
              scrollHeight="300px"
              filterPlaceholder="Buscar por nombre"
              autoFilterFocus
              class="w-full"
              :style="{
                'max-width': windowWidth < 1600 ? '100%' : '60%',
                'min-width': windowWidth < 1600 ? '100%' : '60%',
              }"
              disabled
              :pt="{
                root: ({
                  props, state, context
                }) => ({
                  style: 'height: 2rem;' + (_formSubmitted && !_infoFacturaCapita.U_PHR_MotAutoriza ? 'border-color: rgb(220, 38, 38)' : '')
                }),
                input: { style: 'font-size: 0.875rem; padding: 0.25rem 0.5rem' },
                panel: { style: 'font-size: 0.75rem; max-width: 100px;' },
                item: { style: 'padding: 0.3rem 0.8rem; line-height: 1.4; word-wrap: break-word; white-space: normal; border-bottom: 1px dashed #eee' }
              }"
            />
          </div>
        </div>

        <!-- Contrato autorización -->
        <div class="col-span-12 sm:col-span-12">
          <div class="flex flex-col xxl:flex-row xxl:justify-between xxl:items-center">
            <label for="U_PHR_Cn_Aut" class="block w-full xxl:w-4/7 text-left xxl:text-right text-xs font-medium text-gray-700 mb-1 xxl:mr-2">
              Contrato Autorización:<span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
            </label>
            <Dropdown
              id="U_PHR_Cn_Aut"
              v-model="_infoFacturaCapita.U_PHR_Cn_Aut"
              :options="_listContratoAutorizacion"
              optionLabel="label"
              optionValue="value"
              placeholder="Seleccione una opción"
              :filter="true"
              scrollHeight="300px"
              filterPlaceholder="Buscar por nombre"
              autoFilterFocus
              class="w-full"
              :style="{
                'max-width': windowWidth < 1600 ? '100%' : '60%',
                'min-width': windowWidth < 1600 ? '100%' : '60%',
              }"
              disabled
              :pt="{
                root: ({
                  props, state, context
                }) => ({
                  style: 'height: 2rem;' + (_formSubmitted && !_infoFacturaCapita.U_PHR_Cn_Aut ? 'border-color: rgb(220, 38, 38)' : '')
                }),
                input: { style: 'font-size: 0.875rem; padding: 0.25rem 0.5rem' },
                panel: { style: 'font-size: 0.75rem; max-width: 100px;' },
                item: { style: 'padding: 0.3rem 0.8rem; line-height: 1.4; word-wrap: break-word; white-space: normal; border-bottom: 1px dashed #eee' }
              }"
            />
          </div>
        </div>

        <!-- Mes -->
        <div class="col-span-12 sm:col-span-12">
          <div class="flex flex-col xxl:flex-row xxl:justify-between xxl:items-center">
            <label for="U_PHR_MesCapita" class="block w-full xxl:w-4/7 text-left xxl:text-right text-xs font-medium text-gray-700 mb-1 xxl:mr-2">
              Mes Servicio Capitación:<span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
            </label>
            <Dropdown
              id="mes"
              v-model="_infoFacturaCapita.U_PHR_MesCapita"
              :options="_listMeses"
              optionLabel="label"
              optionValue="value"
              placeholder="Seleccione una opción"
              :filter="true"
              scrollHeight="300px"
              filterPlaceholder="Buscar por nombre"
              autoFilterFocus
              class="w-full"
              :style="{
                'max-width': windowWidth < 1600 ? '100%' : '60%',
                'min-width': windowWidth < 1600 ? '100%' : '60%',
              }"
              :pt="{
                root: ({
                  props, state, context
                }) => ({
                  style: 'height: 2rem;' + (_formSubmitted && !_infoFacturaCapita.U_PHR_MesCapita ? 'border-color: rgb(220, 38, 38)' : '')
                }),
                input: { style: 'font-size: 0.875rem; padding: 0.25rem 0.5rem' },
                panel: { style: 'font-size: 0.75rem; max-width: 100px;' },
                item: { style: 'padding: 0.3rem 0.8rem; line-height: 1.4; word-wrap: break-word; white-space: normal; border-bottom: 1px dashed #eee' }
              }"
            />
          </div>
        </div>

        <!-- Año -->
        <div class="col-span-12 sm:col-span-12">
          <div class="flex flex-col xxl:flex-row xxl:justify-between xxl:items-center">
            <label for="U_PHR_YearCapita" class="block w-full xxl:w-4/7 text-left xxl:text-right text-xs font-medium text-gray-700 mb-1 xxl:mr-2">
              Año Servicio Capitación:<span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
            </label>
            <Dropdown
              id="U_PHR_YearCapita"
              v-model="_infoFacturaCapita.U_PHR_YearCapita"
              :options="_listYears"
              optionLabel="label"
              optionValue="value"
              placeholder="Seleccione una opción"
              :filter="true"
              scrollHeight="300px"
              filterPlaceholder="Buscar por nombre"
              autoFilterFocus
              class="w-full"
              :style="{
                'max-width': windowWidth < 1600 ? '100%' : '60%',
                'min-width': windowWidth < 1600 ? '100%' : '60%',
              }"
              :pt="{
                root: ({
                  props, state, context
                }) => ({
                  style: 'height: 2rem;' + (_formSubmitted && !_infoFacturaCapita.U_PHR_YearCapita ? 'border-color: rgb(220, 38, 38)' : '')
                }),
                input: { style: 'font-size: 0.875rem; padding: 0.25rem 0.5rem' },
                panel: { style: 'font-size: 0.75rem; max-width: 100px;' },
                item: { style: 'padding: 0.3rem 0.8rem; line-height: 1.4; word-wrap: break-word; white-space: normal; border-bottom: 1px dashed #eee' }
              }"
            />
          </div>
        </div>

        <!-- Zonificacion -->
        <div class="col-span-12 sm:col-span-12">
          <div class="flex flex-col xxl:flex-row xxl:justify-between xxl:items-center">
            <label for="Zonificacion" class="block w-full xxl:w-4/7 text-left xxl:text-right text-xs font-medium text-gray-700 mb-1 xxl:mr-2">
              Zona:<span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
            </label>
            <Dropdown
              id="Zonificacion"
              v-model="_infoFacturaCapita.Zonificacion"
              :options="_listZonas"
              optionLabel="DptMunicipio"
              placeholder="Seleccione una opción"
              :filter="true"
              scrollHeight="300px"
              filterPlaceholder="Buscar por nombre"
              autoFilterFocus
              :virtualScrollerOptions="{ itemSize: 45 }"
              class="w-full"
              :style="{
                'max-width': windowWidth < 1600 ? '100%' : '60%',
                'min-width': windowWidth < 1600 ? '100%' : '60%',
              }"
              :pt="{
                root: ({
                  props, state, context
                }) => ({
                  style: 'height: 2rem;' + (_formSubmitted && !_infoFacturaCapita.Zonificacion.DptMunicipio ? 'border-color: rgb(220, 38, 38)' : '')
                }),
                input: { style: 'font-size: 0.875rem; padding: 0.25rem 0.5rem' },
                panel: { style: 'font-size: 0.75rem; max-width: 100px;' },
                item: { style: 'display: flex; align-items:center; padding: 0.3rem 0.8rem; line-height: 1.4; word-wrap: break-word; white-space: normal; border-bottom: 1px dashed #eee' }
              }"
            />
          </div>
        </div>
        <!-- Departamento -->
        <div class="col-span-12 sm:col-span-12">
          <div class="flex flex-col xxl:flex-row xxl:justify-between xxl:items-center">
            <label class="block w-full xxl:w-4/7 text-left xxl:text-right text-xs font-medium text-gray-700 mb-1 xxl:mr-2">
              Departamento:<span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
            </label>
            <div
              class="bg-gray-100 rounded-md p-2 w-full h-8"
              :style="{
                'max-width': windowWidth < 1600 ? '100%' : '60%',
                'min-width': windowWidth < 1600 ? '100%' : '60%',
              }"
            >
              <p class="text-xs">{{ _infoFacturaCapita.Zonificacion.Departamento }}</p>
            </div>
          </div>
        </div>
        <!-- Municipio -->
        <div class="col-span-12 sm:col-span-12">
          <div class="flex flex-col xxl:flex-row xxl:justify-between xxl:items-center">
            <label class="block w-full xxl:w-4/7 text-left xxl:text-right text-xs font-medium text-gray-700 mb-1 xxl:mr-2">
              Municipio:<span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
            </label>
            <div
              class="bg-gray-100 rounded-md p-2 w-full h-8"
              :style="{
                'max-width': windowWidth < 1600 ? '100%' : '60%',
                'min-width': windowWidth < 1600 ? '100%' : '60%',
              }"
            >
              <p class="text-xs">{{ _infoFacturaCapita.Zonificacion.Municipio }}</p>
            </div>
          </div>
        </div>

        <!-- No. Contrato -->
        <div class="col-span-12 sm:col-span-12">
          <div class="flex flex-col xxl:flex-row xxl:justify-between xxl:items-center">
            <label for="U_PHR_NumCtoF" class="block w-full xxl:w-4/7 text-left xxl:text-right text-xs font-medium text-gray-700 mb-1 xxl:mr-2">
              No. Contrato:<span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
            </label>
            <InputText
              id="U_PHR_NumCtoF"
              type="text"
              v-model="_infoFacturaCapita.U_PHR_NumCtoF"
              size="small"
              class="w-full"
              :style="{
                'max-width': windowWidth < 1600 ? '100%' : '60%',
                'min-width': windowWidth < 1600 ? '100%' : '60%',
              }"
              :pt="{
                root: ({
                  props, state, context
                }) => ({
                  style: 'height: 2rem;' + (_formSubmitted && !_infoFacturaCapita.U_PHR_NumCtoF ? 'border-color: rgb(220, 38, 38)' : '')
                }),
                input: { style: 'font-size: 0.875rem' },
                panel: { style: 'font-size: 0.875rem' }
              }"
            />
          </div>
        </div>

        <!-- Regimen -->
<!--        <div class="col-span-12 sm:col-span-12">
          <div class="flex flex-col xxl:flex-row xxl:justify-between xxl:items-center">
            <label for="U_PHR_RegPac" class="block w-full xxl:w-4/7 text-left xxl:text-right text-xs font-medium text-gray-700 mb-1 xxl:mr-2">
              Regimen:<span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
            </label>
            <Dropdown
              id="U_PHR_RegPac"
              v-model="_infoFacturaCapita.U_PHR_RegPac"
              :options="_listRegimen"
              optionLabel="label"
              optionValue="value"
              placeholder="Seleccione una opción"
              :filter="true"
              scrollHeight="300px"
              filterPlaceholder="Buscar por nombre"
              autoFilterFocus
              class="w-full"
              :style="{
                'max-width': windowWidth < 1600 ? '100%' : '60%',
                'min-width': windowWidth < 1600 ? '100%' : '60%',
              }"
              :pt="{
                root: ({
                  props, state, context
                }) => ({
                  style: 'height: 2rem;' + (_formSubmitted && !_infoFacturaCapita.U_PHR_RegPac ? 'border-color: rgb(220, 38, 38)' : '')
                }),
                input: { style: 'font-size: 0.875rem; padding: 0.25rem 0.5rem' },
                panel: { style: 'font-size: 0.75rem; max-width: 100px;' },
                item: { style: 'padding: 0.3rem 0.8rem; line-height: 1.4; word-wrap: break-word; white-space: normal; border-bottom: 1px dashed #eee' }
              }"
            />
          </div>
        </div>-->

        <!-- Regimen F -->
        <div class="col-span-12 sm:col-span-12">
          <div class="flex flex-col xxl:flex-row xxl:justify-between xxl:items-center">
            <label for="U_PHR_RegimenF" class="block w-full xxl:w-4/7 text-left xxl:text-right text-xs font-medium text-gray-700 mb-1 xxl:mr-2">
              Regimen:<span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
            </label>
            <Dropdown
              id="U_PHR_RegPac"
              v-model="_infoFacturaCapita.U_PHR_RegimenF"
              :options="_listRegimenF"
              optionLabel="label"
              optionValue="value"
              placeholder="Seleccione una opción"
              :filter="true"
              scrollHeight="300px"
              filterPlaceholder="Buscar por nombre"
              autoFilterFocus
              class="w-full"
              :style="{
                'max-width': windowWidth < 1600 ? '100%' : '60%',
                'min-width': windowWidth < 1600 ? '100%' : '60%',
              }"
              :pt="{
                root: ({
                  props, state, context
                }) => ({
                  style: 'height: 2rem;' + (_formSubmitted && !_infoFacturaCapita.U_PHR_RegimenF ? 'border-color: rgb(220, 38, 38)' : '')
                }),
                input: { style: 'font-size: 0.875rem; padding: 0.25rem 0.5rem' },
                panel: { style: 'font-size: 0.75rem; max-width: 100px;' },
                item: { style: 'padding: 0.3rem 0.8rem; line-height: 1.4; word-wrap: break-word; white-space: normal; border-bottom: 1px dashed #eee' }
              }"
            />
          </div>
        </div>

        <!-- Estado cartera -->
        <div class="col-span-12 sm:col-span-12">
          <div class="flex flex-col xxl:flex-row xxl:justify-between xxl:items-center">
            <label for="U_PHR_EstadoFac" class="block w-full xxl:w-4/7 text-left xxl:text-right text-xs font-medium text-gray-700 mb-1 xxl:mr-2">
              Estado Factura Cartera:<span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
            </label>
            <Dropdown
              id="U_PHR_EstadoFac"
              v-model="_infoFacturaCapita.U_PHR_EstadoFac"
              :options="_listEstadosCartera"
              optionLabel="label"
              optionValue="value"
              placeholder="Seleccione una opción"
              :filter="true"
              scrollHeight="300px"
              filterPlaceholder="Buscar por nombre"
              autoFilterFocus
              class="w-full"
              :style="{
                'max-width': windowWidth < 1600 ? '100%' : '60%',
                'min-width': windowWidth < 1600 ? '100%' : '60%',
              }"
              :pt="{
                root: ({
                  props, state, context
                }) => ({
                  style: 'height: 2rem;' + (_formSubmitted && !_infoFacturaCapita.U_PHR_EstadoFac ? 'border-color: rgb(220, 38, 38)' : '')
                }),
                input: { style: 'font-size: 0.875rem; padding: 0.25rem 0.5rem' },
                panel: { style: 'font-size: 0.75rem; max-width: 100px;' },
                item: { style: 'padding: 0.3rem 0.8rem; line-height: 1.4; word-wrap: break-word; white-space: normal; border-bottom: 1px dashed #eee' }
              }"
            />
          </div>
        </div>

        <!-- Usuarios Capitacion MES -->
        <div class="col-span-12 sm:col-span-12">
          <div class="flex flex-col xxl:flex-row xxl:justify-between xxl:items-center">
            <label for="U_PHR_UsuarCapita" class="block w-full xxl:w-4/7 text-left xxl:text-right text-xs font-medium text-gray-700 mb-1 xxl:mr-2">
              Usuarios Capitación MES:<span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
            </label>
            <InputNumber
              id="U_PHR_UsuarCapita"
              v-model="_infoFacturaCapita.U_PHR_UsuarCapita"
              class="w-full"
              :minFractionDigits="0"
              :locale="`es-CO`"
              :style="{
                'max-width': windowWidth < 1600 ? '100%' : '60%',
                'min-width': windowWidth < 1600 ? '100%' : '60%',
              }"
              :pt="{
                root:{ style: 'height: 2rem' },
                input: ({
                  props, state, context
                }) => ({
                  style: 'width: 100%; font-size: 0.875rem;' + (_formSubmitted && !_infoFacturaCapita.U_PHR_UsuarCapita ? 'border-color: rgb(220, 38, 38)' : '')
                }),
                panel: { style: 'font-size: 0.875rem' }
              }"
            />
          </div>
        </div>

        <!-- Valor unitario -->
        <div class="col-span-12 sm:col-span-12">
          <div class="flex flex-col xxl:flex-row xxl:justify-between xxl:items-center">
            <label for="U_PHR_VlrUntF" class="block w-full xxl:w-4/7 text-left xxl:text-right text-xs font-medium text-gray-700 mb-1 xxl:mr-2">
              Valor Unitario:<span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
            </label>
            <InputNumber
              id="U_PHR_VlrUntF"
              v-model="_infoFacturaCapita.U_PHR_VlrUntF"
              class="w-full"
              :mode="'currency'"
              :currency="`COP`"
              :locale="`es-CO`"
              :style="{
                'max-width': windowWidth < 1600 ? '100%' : '60%',
                'min-width': windowWidth < 1600 ? '100%' : '60%',
              }"
              :pt="{
                root:{ style: 'height: 2rem' },
                input: ({
                  props, state, context
                }) => ({
                  style: 'width: 100%; font-size: 0.875rem;' + (_formSubmitted && !_infoFacturaCapita.U_PHR_VlrUntF ? 'border-color: rgb(220, 38, 38)' : '')
                }),
                panel: { style: 'font-size: 0.875rem' }
              }"
            />
          </div>
        </div>

        <!-- Creado por -->
        <div class="col-span-12 sm:col-span-12">
          <div class="flex flex-col xxl:flex-row xxl:justify-between xxl:items-center">
            <label class="block w-full xxl:w-4/7 text-left xxl:text-right text-xs font-medium text-gray-700 mb-1 xxl:mr-2">
              Creado por:<span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
            </label>
            <div
              class="bg-gray-100 rounded-md p-2 w-full h-8 overflow-auto"
              :style="{
                'max-width': windowWidth < 1600 ? '100%' : '60%',
                'min-width': windowWidth < 1600 ? '100%' : '60%',
              }"
            >
              <p class="text-xs">{{ user.full_name }}</p>
            </div>
          </div>
        </div>

        <!--Comentario -->
        <div class="col-span-12 sm:col-span-12">
          <div class="flex flex-col xxl:flex-row xxl:justify-between xxl:items-center">
            <label for="U_PHR_Desc1" class="block w-full xxl:w-4/7 text-left xxl:text-right text-xs font-medium text-gray-700 mb-1 xxl:mr-2">
              Comentario:<span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
            </label>
            <Textarea
              id="U_PHR_Desc1"
              v-model="_infoFacturaCapita.U_PHR_Desc1"
              rows="3"
              cols="20"
              class="w-full"
              :style="{
                'max-width': windowWidth < 1600 ? '100%' : '60%',
                'min-width': windowWidth < 1600 ? '100%' : '60%',
              }"
              :pt="{
                root: ({
                  props, state, context
                }) => ({
                  style: (_formSubmitted && !_infoFacturaCapita.U_PHR_Desc1 ? 'border-color: rgb(220, 38, 38)' : '')
                })
              }"
            />
          </div>
        </div>

        <!-- RIPS -->
        <div class="col-span-12 sm:col-span-12">
          <div class="flex flex-col xxl:flex-row xxl:justify-between xxl:items-center">
            <label for="U_PHR_Rips" class="block w-full xxl:w-4/7 text-left xxl:text-right text-xs font-medium text-gray-700 mb-1 xxl:mr-2">
              Rips:<span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
            </label>
            <Dropdown
              id="U_PHR_Rips"
              v-model="_infoFacturaCapita.U_PHR_Rips"
              :options="_listRips"
              optionLabel="label"
              optionValue="value"
              placeholder="Seleccione una opción"
              :filter="true"
              scrollHeight="300px"
              filterPlaceholder="Buscar por nombre"
              autoFilterFocus
              class="w-full"
              :style="{
                'max-width': windowWidth < 1600 ? '100%' : '60%',
                'min-width': windowWidth < 1600 ? '100%' : '60%',
              }"
              :pt="{
                root: ({
                  props, state, context
                }) => ({
                  style: 'height: 2rem;' + (_formSubmitted && !_infoFacturaCapita.U_PHR_Rips ? 'border-color: rgb(220, 38, 38)' : '')
                }),
                input: { style: 'font-size: 0.875rem; padding: 0.25rem 0.5rem' },
                panel: { style: 'font-size: 0.75rem; max-width: 100px;' },
                item: { style: 'padding: 0.3rem 0.8rem; line-height: 1.4; word-wrap: break-word; white-space: normal; border-bottom: 1px dashed #eee' }
              }"
            />
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
