<script setup>
import { onMounted, computed, ref, watch } from 'vue'
import { useInfoFacturasCapitaStore } from '@/apps/pharmasan/facturacion/capita/stores/infoFacturasCapita.store'
import FacturasCapitaValorService from '@/apps/pharmasan/facturacion/capita/services/facturasCapitaValor.service'
import { storeToRefs } from 'pinia'
import UdfFactura from '@/apps/pharmasan/facturacion/capita/views/form/components/udfFactura.vue'
import { useToast } from 'primevue/usetoast'
import { useRouter } from 'vue-router'
import Swal from 'sweetalert2'

const router = useRouter()
const toast = useToast()
const infoFacturasCapitaStore = useInfoFacturasCapitaStore()
const {
  _toggleUdfFactura,
  _infoFacturaCapita,
  _listClientes,
  _listArticulos,
  _listNormaReparto,
  _lastNumFactura,
  _formSubmitted,
  _isValidForm,
  _messageErrors
} = storeToRefs(infoFacturasCapitaStore)
const _FacturasCapitaValorService = ref(new FacturasCapitaValorService())

// Agregar fila
const addRow = () => {
  const maxIndex = Math.max(..._infoFacturaCapita.value.DocumentLines.map(line => line.Index), -1)
  _infoFacturaCapita.value.DocumentLines.push({
    Index: maxIndex + 1,
    ItemCode: 'CAPITA001',
    WarehouseCode: 'BOD0001',
    CostingCode: null,
    Quantity: 1,
    Price: 0,
    PriceTotal: 0
  })
}

// Remover fila de la tabla
const removeRow = (data) => {
  // Si solo hay una fila, no se puede eliminar
  if (_infoFacturaCapita.value.DocumentLines.length > 1) {
    const index = _infoFacturaCapita.value.DocumentLines.indexOf(data)
    if (index > -1) {
      _infoFacturaCapita.value.DocumentLines.splice(index, 1)
      // Reordenar las líneas
      _infoFacturaCapita.value.DocumentLines.forEach((item, index) => {
        item.Index = index
      })
    }
  }
}

// Función cuando cambia el ItemCode
const onItemCodeChange = (line) => {
  if (line.ItemCode === 'CT_0002') {
    line.Quantity = -1
  } else {
    line.Quantity = 1
  }
}

// Computada para calcular el total de la factura
const totalFactura = computed(() => {
  return _infoFacturaCapita.value.DocumentLines.reduce((acc, item) => {
    return acc + (item.Quantity * item.Price)
  }, 0)
})

// Validar campos del formulario
const validateForm = () => {
  const validationsFields = {
    CardCode: 'El campo <span class="text-red-600 font-bold">Cliente</span> es requerido',
    DocDate: 'El campo <span class="text-red-600 font-bold">Fecha de Contabilización</span> es requerido',
    DocDueDate: 'El campo <span class="text-red-600 font-bold">Fecha de Vencimiento</span> es requerido',
    TaxDate: 'El campo <span class="text-red-600 font-bold">Fecha de Documento</span> es requerido',
    Comments: 'El campo <span class="text-red-600 font-bold">Comentarios</span> es requerido',
    U_PHR_ModContrato: 'El campo <span class="text-red-600 font-bold">Modalidad Contrato</span> es requerido',
    U_PHR_MotAutoriza: 'El campo <span class="text-red-600 font-bold">Motivo Autorización</span> es requerido',
    U_PHR_Cn_Aut: 'El campo <span class="text-red-600 font-bold">Contrato Autorización</span> es requerido',
    U_PHR_MesCapita: 'El campo <span class="text-red-600 font-bold">Mes Servicio Capitación</span> es requerido',
    U_PHR_YearCapita: 'El campo <span class="text-red-600 font-bold">Año Servicio Capitación</span> es requerido',
    'Zonificacion.DptMunicipio': 'El campo <span class="text-red-600 font-bold">Zona</span> es requerido',
    // U_PHR_RegPac: 'El campo <span class="text-red-600 font-bold">Regimen</span> es requerido',
    U_PHR_RegimenF: 'El campo <span class="text-red-600 font-bold">Regimen</span> es requerido',
    U_PHR_EstadoFac: 'El campo <span class="text-red-600 font-bold">Estado Factura Cartera</span> es requerido',
    U_PHR_UsuarCapita: 'El campo <span class="text-red-600 font-bold">Usuario Capitación MES</span> es requerido',
    U_PHR_VlrUntF: 'El campo <span class="text-red-600 font-bold">Valor Unitario</span> es requerido',
    U_PHR_Desc1: 'El campo <span class="text-red-600 font-bold">Comentario</span> es requerido',
    U_PHR_Rips: 'El campo <span class="text-red-600 font-bold">RIPS</span> es requerido'
  }

  // Solo agregamos la validación de U_PHR_NumCtoF si CardCode NO es CL900914254
  if (_infoFacturaCapita.value.CardCode !== 'CL800251440') {
    validationsFields.U_PHR_NumCtoF = 'El campo <span class="text-red-600 font-bold">No. Contrato</span> es requerido'
  }

  let isFormValid = true

  for (const field in validationsFields) {
    const fieldParts = field.split('.')
    let value = _infoFacturaCapita.value

    // Recorre las partes del campo (por ejemplo, ['Zonificacion', 'DptMunicipio'])
    for (const part of fieldParts) {
      if (value[part] !== undefined) {
        value = value[part]
      } else {
        value = undefined
        break
      }
    }

    if (!value) {
      _messageErrors.value.push(validationsFields[field])
      isFormValid = false
    }
  }

  // Validar DocumentLines
  _infoFacturaCapita.value.DocumentLines.forEach((line, index) => {
    if (!line.CostingCode) {
      _messageErrors.value.push(`El campo <span class="text-red-600 font-bold">Norma Reparto</span> es requerido en la fila #${index + 1}`)
      isFormValid = false
    }
    if (!line.Quantity) {
      _messageErrors.value.push(`El campo <span class="text-red-600 font-bold">Cantidad</span> es requerido en la fila #${index + 1}`)
      isFormValid = false
    }
    // Validar de que si en la línea el itemCode es CT_0002 la cantidad debe ser exclusivamente -1
    if (line.ItemCode === 'CT_0002' && line.Quantity !== -1) {
      _messageErrors.value.push(`El campo <span class="text-red-600 font-bold">Cantidad</span> debe ser -1 en la fila #${index + 1}`)
      isFormValid = false
    }
    if (!line.Price) {
      _messageErrors.value.push(`El campo <span class="text-red-600 font-bold">Precio</span> es requerido en la fila #${index + 1}`)
      isFormValid = false
    }
  })

  return isFormValid
}

// Calcular precio total y asignarlo a _infoFacturaCapita.value.DocumentLines
const calculatePriceTotal = (line) => {
  line.PriceTotal = line.Quantity * line.Price

  return line.PriceTotal
}

// Enviar a SAP
const onSubmit = async () => {
  _isValidForm.value = true
  _formSubmitted.value = true
  _messageErrors.value = []

  if (!validateForm()) {
    _isValidForm.value = false
    toast.add({ severity: 'error', summary: 'Error', detail: 'Por favor, complete los campos requeridos o valores no permitidos', life: 3000 })
    return
  }

  // Validar que el total de la factura sea mayor a 0
  if (totalFactura.value <= 0) {
    _messageErrors.value.push('El total de la factura debe ser mayor a 0')
    _isValidForm.value = false
    toast.add({ severity: 'error', summary: 'Error', detail: 'El total de la factura debe ser mayor a 0', life: 3000 })
    return
  }

  Swal.fire({
    icon: 'info',
    title: 'Enviar a SAP',
    text: '¿Está seguro de guardar y enviar la factura a SAP?',
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: 'Sí, guardar y enviar!',
    cancelButtonText: 'No, cancelar!',
    confirmButtonColor: '#24963E',
    cancelButtonColor: '#d33'
  }).then(async (result) => {
    if (result.isConfirmed) {
      // Enviar a SAP
      try {
        const response = await _FacturasCapitaValorService.value.saveSendFacturaCapitaValor(_infoFacturaCapita.value)
        if ([200, 201].includes(response.data.status)) {
          // Agregar un sweet alert de éxito y dar la opción de ir a la lista de inventario o ingresar otro registro
          Swal.fire({
            icon: 'success',
            title: 'Exito',
            html: `
              <div class="flex flex-col">
                <span class="text-base">La factura se guardó y envió correctamente a SAP quedando registrada con el siguiente No. <span class="text-green-900 font-bold">${response.data.result.DocNum}</span></span>
              </div>
            `,
            showConfirmButton: true,
            confirmButtonColor: '#24963E',
            confirmButtonText: 'Ir Asociar OVs!',
            showCancelButton: true,
            cancelButtonColor: '#337ddd',
            cancelButtonText: 'Ir al listado!',
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              // Limpiar el store
              infoFacturasCapitaStore._actionClearInfoFacturaCapita()
              // Cargue masivo OV a la factura
              router.push({ name: 'pharmasan.ventas.facturacion.capita-valor.carga-ov-factura', params: { DocNum: response.data.result.DocNum } })
            }

            if (result.isDismissed) {
              // Limpiar el store
              infoFacturasCapitaStore._actionClearInfoFacturaCapita()
              router.push({ name: 'pharmasan.ventas.facturacion.capita-valor.listado' })
            }
          })
        } else {
          console.log('Error al guardar y enviar la factura a SAP: ', response)
          await Swal.fire({
            icon: 'error',
            title: 'Error',
            html: `Hubo un error al guardar y enviar la factura a SAP <br> <span style="font-size: 13px;">${response.data.message ?? ''}</span>`
          })
        }
      } catch (error) {
        console.log('Error al guardar y enviar la factura a SAP: ', error.response)
        await Swal.fire({
          icon: 'error',
          title: 'Error',
          html: `Hubo un error al guardar y enviar la factura a SAP <br> <span style="font-size: 13px;">${error.response.data.message ?? ''}</span> <br> <span style="font-size: 13px;">${error.response.data.details ?? ''}</span>`
        })
      }
    }
  })
}

// Si cambia el valor de _infoFacturaCapita.DocDate, se actualiza el valor de _infoFacturaCapita.DocDueDate y _infoFacturaCapita.TaxDate
watch(() => _infoFacturaCapita.value.DocDate, (newValue) => {
  if (newValue) {
    _infoFacturaCapita.value.DocDueDate = newValue
    _infoFacturaCapita.value.TaxDate = newValue
  }
})

// Hook
onMounted(async () => {
  await infoFacturasCapitaStore._actionGetListModalidadContrato()
  await infoFacturasCapitaStore._actionGetListMotivoAutorizacion()
  await infoFacturasCapitaStore._actionGetListContratoAutorizacion()
  await infoFacturasCapitaStore._actionGetListYears()
  await infoFacturasCapitaStore._actionGetListClientes()
  await infoFacturasCapitaStore._actionGetListNormaReparto()
  await infoFacturasCapitaStore._actionGetLastNumFactura()
  await infoFacturasCapitaStore._actionGetListZonas()
  await infoFacturasCapitaStore._actionGetListRegimenF()
  await infoFacturasCapitaStore._actionGetListEstadosCartera()

  // Limpiar el store
  infoFacturasCapitaStore._actionClearInfoFacturaCapita()
})
</script>

<template>
  <Toast />
  <Toolbar
    class="box my-4 py-2 px-4 flex flex-col sm:flex-row flex-wrap gap-2"
    :pt="{
      end: { class: 'w-full sm:w-auto'}
    }"
  >
    <template #end>
      <div class="flex flex-col sm:flex-row flex-wrap gap-2">
        <!-- Mensajes de error -->
        <Badge
          v-if="!_isValidForm && _formSubmitted"
          :value="`Visualizar mensajes de error de validación`"
          style="height: 1.2rem; font-size: 0.7rem; line-height: 1.2rem;"
          class="bg-red-100 text-red-900 capitalize cursor-pointer font-bold"
          v-tippy="{ content: _messageErrors.join('<br>') }"
        />

        <back-button />
      </div>
    </template>
  </Toolbar>

  <div class="grid md:grid-cols-12 sm:grid-cols-12 grid-cols-1 gap-x-4">

    <!-- Header, detalle, footer factura capita -->
    <div :class="{'col-span-12 sm:col-span-12': !_toggleUdfFactura, 'col-span-12 sm:col-span-12 lg:col-span-12 xl:col-span-9 mb-4 sm:mb-4 lg:mb-4 xl:mb-0': _toggleUdfFactura }">

      <div class="box intro-x h-full">
        <div class="mt-0">
          <!-- Titulo Card -->
          <div class="flex flex-col sm:flex-row items-center p-4 border-b border-gray-200 dark:border-dark-5">
            <h3 class="mr-auto capitalize text-base font-medium">Factura Capita</h3>
            <div class="flex flex-col sm:flex-row gap-2 sm:gap-0">
            </div>
          </div>

          <div class="grid md:grid-cols-12 sm:grid-cols-12 grid-cols-1 gap-4 p-4 overflow-y-auto">
            <!--  -->
            <div class="col-span-12 sm:col-span-5">
              <div class="flex flex-col">
                <!-- Cliente -->
                <div class="flex flex-col xxl:flex-row xxl:justify-between xxl:items-center mb-2">
                  <label for="CardCode" class="w-full xxl:w-1/4 lg:max-w-xs max-w-full text-xs xxl:text-right font-medium text-gray-700 mr-2">
                    Cliente / Entidad:<span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
                  </label>
                  <Dropdown
                    id="CardCode"
                    v-model="_infoFacturaCapita.CardCode"
                    :options="_listClientes"
                    optionLabel="Cliente"
                    optionValue="CardCode"
                    placeholder="Seleccione una opción"
                    :filter="true"
                    scrollHeight="300px"
                    filterPlaceholder="Buscar por nombre"
                    autoFilterFocus
                    class="w-full xxl:w-3/4 lg:max-w-xs max-w-full"
                    :pt="{
                      root: ({
                        props, state, context
                      }) => ({
                        style: 'height: 2rem;' + (_formSubmitted && !_infoFacturaCapita.CardCode ? 'border-color: rgb(220, 38, 38)' : '')
                      }),
                      input: { style: 'font-size: 0.875rem; padding: 0.25rem 0.5rem' },
                      panel: { style: 'font-size: 0.75rem; max-width: 100px;' },
                      item: { style: 'padding: 0.3rem 0.8rem; line-height: 1.4; word-wrap: break-word; white-space: normal; border-bottom: 1px dashed #eee' }
                    }"
                  />
                </div>
                <!-- Num Factura -->
                <div class="flex flex-col xxl:flex-row xxl:justify-between xxl:items-center mb-2">
                  <label for="numFactura" class="block w-full xxl:w-1/4 text-xs xxl:text-right font-medium text-gray-700 mr-2">
                    Número Factura:<span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
                  </label>
                  <InputText
                    id="numFactura"
                    type="text"
                    v-model="_lastNumFactura"
                    size="small"
                    class="w-full xxl:w-3/4 lg:max-w-xs max-w-full"
                    disabled
                    :pt="{
                      root: { style: 'height: 2rem' },
                      input: { style: 'font-size: 0.875rem' },
                      panel: { style: 'font-size: 0.875rem' }
                    }"
                  />
                </div>
              </div>
            </div>

            <div class="col-span-12 sm:col-span-2 hidden sm:block">
              &nbsp;
            </div>

            <!-- Fechas -->
            <div class="col-span-12 sm:col-span-5">
              <div class="flex flex-col">
                <div class="flex flex-col xxl:flex-row xxl:justify-between xxl:items-center mb-2">
                  <label for="DocDate" class="block w-full xxl:w-2/4 text-xs xxl:text-right font-medium text-gray-700 mr-2">
                    Fecha de Contabilización:<span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
                  </label>
                  <InputText
                    id="DocDate"
                    type="date"
                    v-model="_infoFacturaCapita.DocDate"
                    size="small"
                    class="w-full xxl:w-2/4"
                    :pt="{
                      root: ({
                        props, state, context
                      }) => ({
                        style: 'height: 2rem;' + (_formSubmitted && !_infoFacturaCapita.DocDate ? 'border-color: rgb(220, 38, 38)' : '')
                      }),
                      input: { style: 'font-size: 0.875rem' },
                      panel: { style: 'font-size: 0.875rem' }
                    }"
                  />
                </div>
                <div class="flex flex-col xxl:flex-row xxl:justify-between xxl:items-center mb-2">
                  <label for="DocDueDate" class="block w-full xxl:w-2/4 text-xs xxl:text-right font-medium text-gray-700 mr-2">
                    Fecha de Vencimiento:<span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
                  </label>
                  <InputText
                    id="DocDueDate"
                    type="date"
                    v-model="_infoFacturaCapita.DocDueDate"
                    :disabled="true"
                    size="small"
                    class="w-full xxl:w-2/4"
                    :pt="{
                      root: ({
                        props, state, context
                      }) => ({
                        style: 'height: 2rem;' + (_formSubmitted && !_infoFacturaCapita.DocDueDate ? 'border-color: rgb(220, 38, 38)' : '')
                      }),
                      input: { style: 'font-size: 0.875rem' },
                      panel: { style: 'font-size: 0.875rem' }
                    }"
                  />
                </div>
                <div class="flex flex-col xxl:flex-row xxl:justify-between xxl:items-center mb-2">
                  <label for="TaxDate" class="block w-full xxl:w-2/4 text-xs xxl:text-right font-medium text-gray-700 mr-2">
                    Fecha de Documento:<span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
                  </label>
                  <InputText
                    id="TaxDate"
                    type="date"
                    v-model="_infoFacturaCapita.TaxDate"
                    :disabled="true"
                    size="small"
                    class="w-full xxl:w-2/4"
                    :pt="{
                      root: ({
                        props, state, context
                      }) => ({
                        style: 'height: 2rem;' + (_formSubmitted && !_infoFacturaCapita.TaxDate ? 'border-color: rgb(220, 38, 38)' : '')
                      }),
                      input: { style: 'font-size: 0.875rem' },
                      panel: { style: 'font-size: 0.875rem' }
                    }"
                  />
                </div>
              </div>
            </div>

            <!-- Botón agregar filas -->
            <div class="col-span-12 sm:col-span-12">
              <Button
                label="Agregar Fila"
                icon="pi pi-plus"
                size="small"
                severity="primary"
                class="col-span-6 sm:col-span-3 md:col-span-3 lg:col-span-2 xl:col-span-1"
                @click="addRow"
                v-tippy="{ content: 'Agregar fila' }"
                :pt="{
                    root: { style: 'height: 1.8rem' },
                    label: { style: 'font-size: 0.75rem' }
                  }"
              />
            </div>

            <div class="col-span-12 sm:col-span-12" style="height: 484px; border: 1px solid #e5e7eb; border-radius: 0.375rem;">
              <!-- Tabla -->
              <DataTable
                ref="dt"
                :value="_infoFacturaCapita.DocumentLines"
                tableStyle="min-width: 50rem;"
                class="p-datatable-sm text-xs"
                dataKey="id"
                scrollable
                scrollHeight="485px"
              >
                <template #empty> No hay datos para mostrar. </template>
                <template #loading> Cargando la información, por favor espere... </template>
                <Column
                  headerStyle="width: 1rem; text-align: center"
                  bodyStyle="text-align: center; overflow: visible"
                >
                  <template #body="{ data }">
                    <Button
                      type="button"
                      severity="danger"
                      class="p-button-xs"
                      icon="pi pi-trash"
                      v-tippy="{ content: 'Eliminar fila' }"
                      @click="removeRow(data)"
                      :pt="{
                        root: { style: 'width: 1.5rem; height: 1.5rem' }
                      }"
                    />
                  </template>
                </Column>
                <Column
                  field=""
                  header="#"
                >
                  <template #body="{data}">
                    <span>{{ data.Index + 1 }}</span>
                  </template>
                </Column>
                <Column
                  field="ItemCode"
                  header="Artículo"
                >
                  <template #body="{data}">
                    <Dropdown
                      v-model="data.ItemCode"
                      :options="_listArticulos"
                      optionLabel="label"
                      optionValue="value"
                      placeholder="Seleccione una opción"
                      :filter="true"
                      scrollHeight="300px"
                      filterPlaceholder="Buscar por nombre"
                      autoFilterFocus
                      @change="onItemCodeChange(data)"
                      class="text-xs w-full"
                      inputClass="w-full"
                      :pt="{
                        root: ({
                          props, state, context
                        }) => ({
                          style: 'height: 1.75rem;' + (_formSubmitted && !_infoFacturaCapita.DocumentLines[data.Index].ItemCode ? 'border-color: rgb(220, 38, 38)' : '')
                        }),
                        input: { style: 'font-size: 0.75rem; padding: 0.25rem 0.5rem' },
                        panel: { style: 'font-size: 0.75rem; max-width: 100px;' },
                        item: { style: 'display: flex; align-items:center; padding: 0.3rem 0.8rem; line-height: 1.4; word-wrap: break-word; white-space: normal; border-bottom: 1px dashed #eee' }
                      }"
                    />
                  </template>
                </Column>
                <Column
                  field="WarehouseCode"
                  header="Bodega"
                ></Column>
                <Column
                  field="CostingCode"
                  header="Norma Reparto"
                >
                  <template #body="{data}">
                    <Dropdown
                      v-model="data.CostingCode"
                      :options="_listNormaReparto"
                      optionLabel="NormasReparto"
                      optionValue="OcrCode"
                      placeholder="Seleccione una opción"
                      :filter="true"
                      scrollHeight="300px"
                      filterPlaceholder="Buscar por nombre"
                      autoFilterFocus
                      :virtualScrollerOptions="{ itemSize: 45, resizeDelay: 0 }"
                      class="text-xs w-full"
                      inputClass="w-full"
                      :pt="{
                        root: ({
                          props, state, context
                        }) => ({
                          style: 'height: 1.75rem;' + (_formSubmitted && !_infoFacturaCapita.DocumentLines[data.Index].CostingCode ? 'border-color: rgb(220, 38, 38)' : '')
                        }),
                        input: { style: 'font-size: 0.75rem; padding: 0.25rem 0.5rem' },
                        panel: { style: 'font-size: 0.75rem; max-width: 100px;' },
                        item: { style: 'display: flex; align-items:center; padding: 0.3rem 0.8rem; line-height: 1.4; word-wrap: break-word; white-space: normal; border-bottom: 1px dashed #eee' }
                      }"
                    />
                  </template>
                </Column>
                <Column
                  field="Quantity"
                  header="Cantidad"
                >
                  <template #body="{data}">
                    <InputNumber
                      v-model="data.Quantity"
                      class="w-full"
                      :minFractionDigits="0"
                      :locale="`es-CO`"
                      :pt="{
                        root: { style: 'height: 1.75rem;' },
                        input: ({
                          props, state, context
                        }) => ({
                          style: 'width: 100%; font-size: 0.75rem;' + (_formSubmitted && !_infoFacturaCapita.DocumentLines[data.Index].Quantity ? 'border-color: rgb(220, 38, 38)' : '')
                        })
                      }"
                    />
                  </template>
                </Column>
                <Column
                  field="Price"
                  header="Precio"
                >
                  <template #body="{data}">
                    <InputNumber
                      v-model="data.Price"
                      class="w-full"
                      :mode="'currency'"
                      :currency="`COP`"
                      :locale="`es-CO`"
                      :pt="{
                        root: { style: 'height: 1.75rem;' },
                        input: ({
                          props, state, context
                        }) => ({
                          style: 'width: 100%; font-size: 0.75rem;' + (_formSubmitted && !_infoFacturaCapita.DocumentLines[data.Index].Price ? 'border-color: rgb(220, 38, 38)' : '')
                        })
                      }"
                    />
                  </template>
                </Column>
                <Column
                  field="PriceTotal"
                  header="Precio Total"
                  style="text-align: right"
                >
                  <template #body="{data}">
                    <!-- Calcular el precio total Quantity * Price -->
                    <span>{{ $h.formatCurrency(parseFloat(calculatePriceTotal(data)), 2) }}</span>
                  </template>
                </Column>
              </DataTable>
            </div>

            <!--  -->
            <div class="col-span-12 lg:col-span-5">
              <div class="flex flex-col">
                <!-- Comentarios -->
                <div class="flex flex-col sm:flex-row sm:justify-between sm:items-start mb-2">
                  <label for="Comments" class="block w-full sm:w-1/4 text-xs font-medium text-gray-700 mr-2">
                    Comentarios:<span class="font-bold text-red-700 text-sm" v-tippy="{ content: 'Campo requerido' }">*</span>
                  </label>
                  <Textarea
                    v-model="_infoFacturaCapita.Comments"
                    rows="3"
                    cols="20"
                    class="w-full"
                    :pt="{
                      root: ({
                        props, state, context
                      }) => ({
                        style: (_formSubmitted && !_infoFacturaCapita.Comments ? 'border-color: rgb(220, 38, 38)' : '')
                      })
                    }"
                  />
                </div>
              </div>
            </div>

            <div class="col-span-12 lg:col-span-3 hidden lg:block">
              &nbsp;
            </div>

            <!-- Totales -->
            <div class="col-span-12 lg:col-span-4">
              <div class="flex flex-col">
                <div class="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-2">
                  <span class="block w-full sm:w-2/4 text-xs sm:text-right font-medium text-gray-700 mr-2">Total antes del descuento:</span>
                  <div class="bg-gray-100 rounded-md p-2 w-full sm:w-2/4 h-8">
                    <p class="text-xs text-right">{{$h.formatCurrency(totalFactura, 2)}}</p>
                  </div>
                </div>
                <div class="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-2">
                  <label class="block sm:w-2/4 text-xs sm:text-right font-medium text-gray-700 mr-2">Descuento:</label>
                  <InputNumber
                    v-model="_infoFacturaCapita.DiscountPercent"
                    mode="decimal"
                    :minFractionDigits="1"
                    :maxFractionDigits="1"
                    class=""
                    :pt="{
                      root: { style: 'height: 1.75rem' },
                      input: { style: 'width: 4rem; font-size: 0.75rem' }
                    }"
                  />
                  <p class="mr-2">%</p>
                  <div class="bg-gray-100 rounded-md p-2 h-8" style="width: 30%">
                    <p class="text-xs text-right">{{$h.formatCurrency(totalFactura * (_infoFacturaCapita.DiscountPercent / 100), 2)}}</p>
                  </div>
                </div>
                <div class="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-2">
                  <span class="block w-full sm:w-2/4 text-xs sm:text-right font-medium text-gray-700 mr-2">Total:</span>
                  <div class="bg-gray-100 rounded-md p-2 w-full sm:w-2/4 h-8">
                    <p class="text-xs text-right">{{ _infoFacturaCapita.DiscountPercent ? $h.formatCurrency(totalFactura - (totalFactura * (_infoFacturaCapita.DiscountPercent / 100)), 2) : $h.formatCurrency(totalFactura, 2) }}</p>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>

    </div>

    <!-- UDF de la factura capita -->
    <div
      v-if="_toggleUdfFactura"
      :class="{'col-span-12 sm:col-span-12 lg:col-span-12 xl:col-span-3': _toggleUdfFactura, 'hidden': !_toggleUdfFactura}"
    >
      <UdfFactura />
    </div>
  </div>

  <Button
    label="Enviar a SAP"
    icon="pi pi-send"
    severity="success"
    size="small"
    class="p-button-raised floating-button p-button-rounded"
    @click="onSubmit"
  />
</template>

<style scoped>
.floating-button {
  position: fixed !important;
  bottom: 30px;
  right: 40px;
  z-index: 1050;
}
</style>
